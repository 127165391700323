



















import { Component, Prop } from 'nuxt-property-decorator';
import Vue from 'vue';

@Component
export default class LinkInternal extends Vue {
  @Prop() content!: { url: string, title: string, hasPrep: boolean, anchor: false | string };

  get anchor (): string {
    if (this.content.anchor) {
      return `#${this.content.anchor}`;
    } else {
      return '';
    }
  }

  get isValidLink (): boolean {
    return (this.content.url !== '') && (this.content.url !== `/${this.$i18n.locale}/`);
  }

  get isHome (): boolean {
    return this.content.url ? this.content.url.endsWith('home') && this.content.url.length < 9 : false;
  }

  get link (): { path: string } {
    if (this.content.hasPrep) {
      if (this.anchor) {
        return { path: `/${this.content.url.slice(4, this.content.url.length)}#${this.content.anchor}` };
      } else {
        return { path: `/${this.content.url.slice(4, this.content.url.length)}` };
      }
    } else if (this.anchor) {
      return { path: `/${this.content.url}#${this.content.anchor}` };
    } else {
      return { path: `/${this.content.url}` };
    }
  }
}
